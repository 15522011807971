<template>
	<div class="">
		<div class="header">
			<a-row justify="space-between" >
				<a-col :push="1">
					<img class="logo animate__animated " data-animation="animate__slideInLeft" src="@/assets/headlogo.png" />
				</a-col>
				<a-col :pull="1" class="head_options animate__animated " data-animation="animate__slideInRight">
					<img src="@/assets/dc.png" class="icon2"/>
					<a href="https://t.me/Onblox_Global"><img src="@/assets/telegram.png" class="icon2"/></a>
					<img src="@/assets/github.png" class="icon2"/>
					<a href="https://x.com/Onblox"><img src="@/assets/x.png" class="icon2"/></a>
				</a-col>
			</a-row>
		</div>
		<div class="content">
			<a-row justify="center">
				<div class="cutom_row animate__animated " data-animation="animate__zoomIn">
					<div class="top1">
						<div class="top_txt">
							AI Onblox: A Zero-Knowledge<br/>
							DePIN on TON for GPU Sharing<br/>
							and Constant Rewards
						</div>
						<div class="top_txt2">
							Seamless access,no intermediaries,with continuous rewards and global GPU distribution.
						</div>
					</div>
					<div class="wapper_rect">
						<div class="wapper"><a href="https://onblox.gitbook.io/whitepaper">
							<a-button class="btn" @click="whitepaperClick">
								<img src="@/assets/st.png" class="icon1" />
								Whitepaper
							</a-button>
							</a>
							<a-button class="btn">
								<img src="@/assets/yr.png" class="icon1" />
								User Manual
							</a-button>
						</div>
						<div class="sponer">
							Sponsored by <span class="bold2">F2Pool</span> & <span class="bold2">CDH</span>
						</div>
						<!-- <div class="float_btn">
							<div class="wapper">
								<a-button class="btn btn_color">
									<SnippetsFilled />
									Whitepaper
								</a-button>
								<a-button class="btn btn_color">
									<ContainerFilled />
									User Manual
								</a-button>
							</div>
							<div class="sponer">
								<span class="bold1">（鼠标滑过）</span> <span class="bold2">F2Pool</span> & <span class="bold2">CDH</span>
							</div>
						</div> -->
					</div>
				</div>
			</a-row>
			<a-row class="metrics_cont" justify="center">
				<div class="cutom_row">
					<div class="title_view animate__animated " data-animation="animate__slideInUp">
						<span class="title2">Key Metrics</span>
						<span class="txt1">Updated in real-time</span>
					</div>
					<div class="metrics">
						<a-row :wrap="true">
							<a-col :md="8" :xl="8" :xxl="8" :xs="12" class="tab_item animate__animated " data-animation="animate__zoomIn">
								<span class="num1">{{ gpuAmount }}</span>
								<span class="txt2">GPU Nodes Connected</span>
							</a-col>
							<a-col :md="8" :xl="8" :xxl="8" :xs="12" class="tab_item animate__animated " data-animation="animate__zoomIn">
								<span class="num1">{{ countryAmount }} </span>
								<span class="txt2">Countries Covered</span>
							</a-col>
							<a-col :md="8" :xl="8" :xxl="8" :xs="12" class="tab_item animate__animated " data-animation="animate__zoomIn">
								<span class="num1">$ {{ rechargeAmount }}K</span>
								<span class="txt2">Rewards Paid</span>
							</a-col>
							<a-col :md="8" :xl="8" :xxl="8" :xs="12" class="tab_item animate__animated " data-animation="animate__zoomIn">
								<span class="num1">$ {{ costAmount }}K</span>
								<span class="txt2">In Compute Transactions</span>
							</a-col>
							<a-col :md="8" :xl="8" :xxl="8" :xs="12" class="tab_item animate__animated " data-animation="animate__zoomIn">
								<span class="num1">{{ uptimePercent }}%</span>
								<span class="txt2">Network Uptime</span>
							</a-col>
							<a-col :md="8" :xl="8" :xxl="8" :xs="12" class="tab_item animate__animated " data-animation="animate__zoomIn">
								<span class="num1">{{ userAmount }}</span>
								<span class="txt2">Active Users</span>
							</a-col>
						</a-row>
					</div>
				</div>
			</a-row>
			<a-row class="cuting_cont" justify="center">
				<div class="cutom_row">
					<div class="title_view animate__animated " data-animation="animate__slideInUp">
						<span class="title2">Cutting-edge DePIN Technology</span>
						<span class="txt1 ">Innovative,Decentralized Solutions for the Future</span>
					</div>
					<div class="cuting">
						<a-row :wrap="true">
							<a-col :md="8" :xl="8" :xxl="8" :xs="24" class="cuting_item border1 animate__animated" data-animation="animate__zoomIn">
								<img  src="@/assets/1.png" class="custing_img"/>
								<span class="title3">Funtionial Comuter</span>
								<span class="txt5">All Al apps are deployed through ourpatented functional computing system</span>
							</a-col>
							<a-col :md="8" :xl="8" :xxl="8" :xs="24" class="cuting_item border1 animate__animated" data-animation="animate__zoomIn">
								<img  src="@/assets/2.png" class="custing_img"/>
								<span class="title3">Decentralized Security</span>
								<span class="txt5">Cephalon Network is secured bydecentralized competitions and zero.knowledge encryptions, making datavirtually impenetrable</span>
							</a-col>
							<a-col :md="8" :xl="8" :xxl="8" :xs="24" class="cuting_item border1 animate__animated" data-animation="animate__zoomIn">
								<img  src="@/assets/3.png" class="custing_img"/>
								<span class="title3">High Scalability</span>
								<span class="txt5">AlGC, decentralized NAS, remote gamingand innovative new apps are constantlybeing updated</span>
							</a-col>
						</a-row>
					</div>
				</div>
			</a-row>
			<a-row class="qa_cont" justify="center">
				<div class="cutom_row">
					<div class="title_view  animate__animated" data-animation="animate__backInDown">
						<span class="title2">Q & A</span>
					</div>
					<div class="qa_list  animate__animated" data-animation="animate__backInUp">
						<a-collapse
						v-model:activeKey="activeKey"
						:bordered="false"
						expandIconPosition="end"
						style="background: rgb(255, 255, 255)"
						>
							<template class="custom_arrow" #expandIcon="{ isActive }">
								<img
									:src="isActive ? activeIcon : inactiveIcon" 
									alt="箭头图标" 
									class="custom-arrow-icon" 
								/>
							</template>
							<a-collapse-panel class="nav_title3" v-for="(tp, k) in nav" 
							:key="k" 
							:header="tp.name"
							:style="customStyle"
							>
								<p class="nav_descpe">{{ tp.desc }}</p>
							</a-collapse-panel>
						</a-collapse>

						<!-- <a-collapse class="custom-collapse" 
						bordered
						v-model:activeKey="activeKeys"
						>
							<a-collapse-panel class="custom-panel"
							v-for="(tp, k) in nav"
							:key="k"
							:header="tp.name"
							>
							<span>{{ tp.desc || '' }}</span>
							</a-collapse-panel>
						</a-collapse> -->
						<!-- <div class="nav_item" v-for="(tp,k) in nav">
							<div class="nav_top" @click.stop="tp.open=!tp.open">
								<span class="nav_title3">{{tp.name}}</span>
								<img v-if="tp.open==1" src="@/assets/dec.png" class="icon3"/>
								<img v-else src="@/assets/add.png" class="icon3"/>
							</div>
							<div class="nav_descpe" v-if="tp.open">
								<span>{{tp.desc||''}}</span>
							</div>
						</div> -->
					</div>
				</div>
			</a-row>
			<div class="foot">
				<a-row justify="center">
					<div class="cutom_row">
						<a-row :gutter="24" justify="space-between" >
							<a-col class="bottom_left animate__animated " data-animation="animate__slideInLeft">
								<div class="">
									<img class="logo " src="@/assets/headlogo.png" />
								</div>
								<span class="txt4 margin_top10">© Copyright AIOblox Inc. 2024 - 2025</span>
							</a-col>
							<a-col class="bottom_right animate__animated " data-animation="animate__slideInRight">
								<div class="head_options ">
									<img src="@/assets/dc.png" class="icon2"/>
									<a href="https://t.me/Onblox_Global"><img src="@/assets/telegram.png" class="icon2"/></a>
									<img src="@/assets/github.png" class="icon2"/>
									<a href="https://x.com/Onblox"><img src="@/assets/x.png" class="icon2"/></a>
								</div>
								<span class="txt3 margin_top10">Service Agreement | Privacy Pollcy</span>
							</a-col>
						</a-row>
					</div>
				</a-row>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref } from 'vue';
	import axios from 'axios';
	
	export default {
		name:'index',
		
		data(){
			return {
				nav:[
					{name:'What is AI Onblox?',desc:'AI Onblox is a ZK-DePIN platform with integrated GPU resources. It enables developers to deploy decentralized AIGC applications and allows users to access these apps directly from AIGC app stores, creating a seamless ecosystem for AI-driven innovation.'},
					{name:'How can l particpate in the Onblox network?',desc:'You can join the AI Onblox network as a service provider (miner) by downloading and deploying the miner app from GitHub on your own hardware. Alternatively, you can opt for third-party one-click-to-earn miner hardware for a more convenient setup.'},
					{name:'What rewards can I earn by sharing my GPU?',desc:'AI Onblox issues OLX tokens as rewards to users who share their GPU resources (farming rewards) and to those who complete AIGC tasks (mining rewards). You can track your rewards directly through the TON explorer.'},
					{name:'Is my data secure on the AI Onblox network?',desc:'Yes, your data remains secure. Zero-knowledge techniques ensure that no personal information is exposed to the public or to service providers, safeguarding your privacy throughout the network.'},
					{name:'Can I withdraw my earnings at any time?',desc:"Absolutely. OLX rewards are settled daily, and you can withdraw your earnings immediately without any restrictions."}
				],
				activeKey: ref(['1']),
				text: `A dog is a type of domesticated animal.Known for its loyalty and faithfulness,it can be found as a welcome guest in many households across the world.`,
				customStyle: 'padding:15px 15px;box-sizing: border-box;background: #FFFFFF;box-shadow: 0px 2px 15px 0px rgba(148,149,176,0.2);margin:10px 0;',
				activeIcon: require('@/assets/dec.png'), // 替换为展开状态的 PNG 图片路径
				inactiveIcon: require('@/assets/add.png'), // 替换为收起状态的 PNG 图片路径,
				icon_position:'right',
				show:false,
				gpuAmount: 6,
				countryAmount: 23,
				rechargeAmount: '46,330',
				costAmount: 754,
				uptimePercent: 98.95,
				userAmount: 62
			}
		},
		mounted() {
			setTimeout(()=>{
				this.show=true;
			},1000)
			let arr=document.querySelectorAll('.animate__animated');
			arr.forEach((val)=>{
				observer.observe(val);
			})
			setInterval(()=>{
				this.fetchData();
			},5000)
		},
		methods:{
			whitepaperClick() {
				window.location.href = 'https://onblox.gitbook.io/whitepaper';
			},
			fetchData() {
				axios.get('https://test.unicorn.org.cn/cephalon/user-center/v1/statistics/onblox-home')
				.then(response => {
					this.gpuAmount = new Intl.NumberFormat().format(response.data.data.gpu_amount);
					this.countryAmount = new Intl.NumberFormat().format(response.data.data.country_amount);
					this.rechargeAmount = new Intl.NumberFormat().format(response.data.data.recharge_amount);
					this.costAmount = new Intl.NumberFormat().format(response.data.data.cost_amount);
					this.uptimePercent = new Intl.NumberFormat().format(response.data.data.uptime_percent);
					this.userAmount = new Intl.NumberFormat().format(response.data.data.user_amount);
				})
				.catch(error => {
					console.error('There was an error!', error);
				});
			}
		}
	}
	const observer = new IntersectionObserver((entries) => {
		entries.forEach(entry => {
			//console.log(entry);
			if (entry.isIntersecting) {
				//console.log(entry.target.dataset);
				if(entry.target.dataset.animation){
					entry.target.classList.add(entry.target.dataset.animation);
				}
			}
		});
	});
</script>

<style scoped>
.content{
	box-sizing: border-box;
}
.header{
	padding:15px 0;
	box-sizing:border-box;
	width:100%;
}
.title{
	color:#444;
	font-size:20px;
	font-weight: bold;
}
.logo{
	font-size:24px;
	display: block;
	width:160px;
}
.head_options{
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.icon2{
	width:20px;
	height:20px;
	margin-left:20px;
}
.cutom_row{
	max-width:100%;
	width:1200px;
	box-sizing: border-box;
}
.top1{
	padding:60px 0;
}
.top_txt{
	text-align: center;
	font-family: Arial, Arial;
	font-weight: 900;
	font-size: 66px;
	color: #000000;
	line-height: 80px;
	text-align: center;
	font-style: normal;
}
.top_txt2{
	font-family: ArialMT;
	font-size: 18px;
	color: #9495B0;
	line-height: 21px;
	text-align: center;
	font-style: normal;
	margin-top:40px;
	text-align: center;
}
.title_view{
	width:100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.title2{
	font-family: Arial, Arial;
	font-weight: 900;
	font-size: 48px;
	color: #000000;
	line-height: 60px;
	text-align: center;
	font-style: normal;
}
.txt1{
	font-family: ArialMT;
	font-size: 18px;
	color: #9495B0;
	line-height: 21px;
	text-align: center;
	font-style: normal;
	margin-top:20px;
}
.metrics_cont{
	margin-top:140px;
}
.metrics{
	flex-wrap: wrap;
	margin-top:30px;
}
.tab_item{
	text-align:center;
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height:217px;
	box-sizing: border-box;
	border:0.3px solid #EEEEEE;
}
.border1{
	border:0.3px solid #EEEEEE;
	box-sizing:border-box;
}
.num1{
	
	font-family: Arial, Arial;
	font-weight: 900;
	font-size: 48px;
	color: #000000;
	line-height: 60px;
	text-align: center;
	font-style: normal;
}
.txt2{
	font-family: ArialMT;
	font-size: 16px;
	color: #9495B0;
	line-height: 18px;
	text-align: center;
	font-style: normal;
}

.cuting_cont{
	margin-top:140px;
}
.cuting{
	margin-top:40px;
}
.cuting_item{
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height:434px;
}
.custing_img{
	width:240px;
	height:240px;
}
.title3{
	font-family: Arial, Arial;
	font-weight: 900;
	font-size: 24px;
	color: #000000;
	line-height: 36px;
	text-align: center;
	font-style: normal;
}
.txt5{
	font-family: ArialMT;
	font-size: 16px;
	color: #9495B0;
	line-height: 18px;
	text-align: center;
	font-style: normal;
	width:304px;
	height:80px;
	margin-top:20px;
}
.wapper_rect{
	display: flex;
	flex-direction: column;
	justify-content:center;
	align-items: center;
}
.icon1{
	width:12px;
	height:14px;
	margin-right:8px;
}
.sponer{
	font-family: ArialMT;
	font-size: 14px;
	color: #9495B0;
	line-height: 22px;
	text-align: left;
	font-style: normal;
	margin-top:20px;
	text-align: center;
}


.bold1{
	font-weight: bold;
	color:#111;
}
.bold2{
	font-weight: bold;
	color:#111;
	text-decoration:underline;
}

.bold2:hover{
	color: #6CA4FC;
	cursor: pointer
}

.qa_cont{
	margin-top:140px;
}
.qa_list{
	margin-top:30px;
}
.nav_item{
	padding:15px 15px;
	box-sizing: border-box;
	background: #FFFFFF;
	box-shadow: 0px 2px 15px 0px rgba(148,149,176,0.2);
	margin:10px 0;
}
.nav_top{
	display: flex;
	align-items: center;
	justify-content: space-between;
	height:60px;
}
.nav_title3{
	font-family: PingFangSC, PingFang SC;
	font-weight: 600;
	font-size: 24px;
	color: #000000;
	line-height: 33px;
	text-align: left;
	font-style: normal;
}
.nav_descpe{
	font-family: ArialMT;
	font-size: 16px;
	color: #9495B0;
	line-height:1.5;
	text-align: left;
	font-style: normal;
	margin-top:20px;
}
.icon3{
	height:24px;
	width:24px;
}
.foot{
	margin-top:20px;
	padding:30px 0;
	box-sizing: border-box;
}
.txt4{
	font-family: ArialMT;
	font-size: 14px;
	color: #000000;
	line-height: 16px;
	text-align: left;
	font-style: normal;
}
.txt3{
	font-family: ArialMT;
	font-size: 14px;
	color: #9495B0;
	line-height: 16px;
	text-align: right;
	font-style: normal;
}
.margin_top10{
	margin-top:10px;
}
.bottom_left{
	display: flex;
	flex-direction:column;
}
.bottom_right{
	display: flex;
	flex-direction:column;
}

.wapper{
	display: flex;
	justify-content: center;
}
.btn{
	color:#fff;
	background-color:#333;
	border-radius:0;
	width:166px;
	height:36px;
	display:flex;
	align-items: center;
	justify-content: center;
	margin:0 8px;
	
	font-family: Arial, Arial;
	font-weight: normal;
	font-size: 14px;
	color: #FFFFFF;
	text-align: center;
	font-style: normal;
}

.btn:hover{
	background-color: #6CA4FC;
	color: #FFFFFF;
}

.float_btn{
	position: absolute;
	right:0px;
	top:480px;
	display: none;
}
.btn_color{
	background-color: #6CA4FC;
}

.custom-collapse {
  /* border: 2px solid #FFFFFF; */
  border-radius: 0px;
}

.custom-panel {
	padding:15px 15px;
	box-sizing: border-box;
	background: #FFFFFF;
	box-shadow: 0px 2px 15px 0px rgba(148,149,176,0.2);
	margin:10px 0;
}

.custom_arrow{
	display: flex;
	align-items: center;
	height:40px;
}
/* 覆盖 Ant Design 默认的 expand-icon 样式 */
.custom-arrow-icon {
  width: 16px; /* 调整箭头大小 */
  height: 16px;
  transition: transform 0.3s; /* 添加平滑动画 */
  margin-top:12px;
}

/* 右对齐箭头图标 */
.a-collapse-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.a-collapse-header .custom-arrow-icon {
  margin-left: auto; /* 将箭头图标推到右侧 */
}

/* .wapper_rect:hover .btn{
	background-color: #6CA4FC;
	color:#fff;
}
.wapper_rect:hover .sponer{
	color:#111;
}
.wapper_rect:hover .bold2{
	color:#6CA4FC;
} */


.logo-enter-active,
.logo-leave-active {
  transition: opacity 5s ease;
}

.logo-enter-from,
.logo-leave-to {
  opacity: 0;
}
</style>